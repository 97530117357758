<template>
  <div class="selectaddr">
    <!-- <title-top>我的地址</title-top> -->
    <div class="row align-center bg-white h96 mb16">
      <div class="row flex-center" style="width:1.173333rem;">
        <img class="imgsize44" src="~images/fanhui.png" alt />
      </div>
      <span class="flex1 row flex-center font36 f-1A1A1A">选择地址</span>
      <div class="row flex-center font24" style="width:1.333333rem">
        <span>管理</span>
      </div>
    </div>
    <div class="row flex-start bg-white">
      <img class="w100 bg-white" src="~images/orderban.png" alt />
    </div>
    <div class="row between align-center bg-white h210">
      <div class="column font24 f-333333 ml25">
        <div class="row mb20">
          <span>旅途</span>
          <span class="ml40">55888</span>
        </div>
        <div class="row align-center">
          <img class="imgsize32" src="~images/dingwei.png" alt />
          <span class="f-999999">河北省邢台县快乐旅途</span>
        </div>
      </div>
    </div>
    <div class="row flex-start bg-white">
      <img class="w100 bg-white" src="~images/orderban.png" alt />
    </div>
    <div class="row flex-center" @click="onSure">
      <div
        class="btn-red mt100 position_f"
        style="background-color:#41ABA7;width:8rem;bottom:0;"
      >添加地址</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from "@/components/common/title/TitleTop";

export default {
  name: "SelectAddr",
  data() {
    return {};
  },
  components: {
    TitleTop
  }
};
</script>
<style  scoped>
</style>
